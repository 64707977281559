import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardPage from "./dashboard";
import LoginPage from "./login";
import { AuthContext } from "../auth/context";

export default function App() {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <DashboardPage /> : <LoginPage />}
        />
      </Routes>
    </BrowserRouter>
  );
}
