import { EditOutlined } from "@ant-design/icons";
import { Layout, List, Menu, MenuProps } from "antd";
const { Header, Content } = Layout;

const items: MenuProps["items"] = [
  {
    label: "New Game",
    key: "create",
    onClick: () => {},
    icon: <EditOutlined />,
  },
];

export default function DashboardPage(): JSX.Element {
  return (
    <Layout>
      <Header style={{ display: "flex", alignItems: "center" }}>
        <Menu
          theme="dark"
          mode="horizontal"
          activeKey="create"
          defaultSelectedKeys={[]}
          items={items}
          style={{ flex: 1, minWidth: 0 }}
        />
      </Header>
      <Content style={{ padding: "0 48px" }}>
        <div
          style={{
            minHeight: "100vh",
            padding: 24,
          }}
        >
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={[
              { name: "Test Game", description: "This is a test game." },
              { name: "Test Game 2", description: "This is a test game 2." },
            ]}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <a key="list-loadmore-edit">edit</a>,
                  <a key="list-loadmore-more">more</a>,
                ]}
              >
                <List.Item.Meta
                  title={item.name}
                  description={item.description}
                />
                <div>content</div>
              </List.Item>
            )}
          />
        </div>
      </Content>
    </Layout>
  );
}
